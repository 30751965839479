// eslint-disable-next-line import/named
import { DataBinding } from '../databinding/databinding';
import { DataTracker } from '../databinding/data-tracker';
import { FormInputSelectValue } from '../../FormInputView';
import { html, TemplateResult } from 'lit';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';
import { InputEventMap } from './form-input';

export function formRadioGroup(
  fieldName: string,
  title: string | undefined,
  values: string | FormInputSelectValue[],
  dataBinding: DataBinding,
  dataTracker: DataTracker,
  options?: {
    readOnly?: boolean;
    required?: boolean;
    class?: string;
    events?: InputEventMap;
    immediateBindingUpdate?: boolean;
  }
): TemplateResult {
  const internalOptions = values ?? [];

  const optionsArray: FormInputSelectValue[] =
    typeof internalOptions === 'string' ? JSON.parse(internalOptions) : internalOptions;

  const _options: { text: string; value: string; selected: boolean; disabled?: boolean }[] = [];

  optionsArray.forEach(x =>
    _options.push({
      text: x.text,
      value: x.value,
      selected: dataTracker.getObjectValue(fieldName) === x.value,
      disabled: options?.readOnly || x.disabled
    })
  );

  const opts = html`${repeat(
    _options,
    x => x.value,
    (x, _index) => html` <webmodule-radio value="${x.value}" ?disabled="${x.disabled}">${x.text}</webmodule-radio>`
  )}`;
  const changeEvent = (e: Event) => {
    if (options?.immediateBindingUpdate) dataTracker.getBinder(fieldName)?.applyChangeToValue();
    options?.events?.change?.(e);
  };
  const blurEvent = (e: Event) => {
    if (options?.immediateBindingUpdate) dataTracker.getBinder(fieldName)?.applyChangeToValue();
    options?.events?.blur?.(e);
  };
  return html` <webmodule-radio-group
    class="${options?.class ?? ''} webmodule-control-left-label"
    id=${dataBinding.field(fieldName)}
    name=${dataBinding.field(fieldName)}
    label="${ifDefined(title)}:"
    ?required=${options?.required}
    value=${dataTracker.getObjectValue(fieldName)}
    @webmodule-blur=${blurEvent}
    @webmodule-change=${changeEvent}
    @webmodule-focus=${options?.events?.focus}
    @webmodule-input=${options?.events?.input}
    size="small"
    inline
  >
    ${opts}
  </webmodule-radio-group>`;
}
